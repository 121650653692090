import { Component, inject } from "@angular/core";
import {
  ShipmentQuoteListDto,
  ShipmentsClient,
  FilterDto,
  FiltersClient,
  ListTypeEnum,
  UserSettingDto,
  GridColumnDto,
  ColumnTypeEnum,
  ServiceTypeEnum,
} from "../../../core/services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { UserResolverService } from "../../../core/services/user-resolver.service";
import { SpinnerService } from "../../../core/spinner/spinner.svc";
import { Observable, of, Subject } from "rxjs";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { GridBaseComponent } from "../../../shared/base-components/material-grid-base/material-grid-api-base.component";
import { RequestService } from "../../../core/services/request.service";
import { UserSettingService } from "../../../core/services/user-setting.service";
import { ApiGridStateModel } from "../../../models/grid-state.model";
import { CancelShipmentService } from "../../../core/services/cancel-shipment.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MaterialGridEmptyTableComponent } from "../../../shared/material-grid-empty-table/material-grid-empty-table.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { GridColumnDisplayComponent } from "../../../shared/grid-column-display/grid-column-display.component";

import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HasPermissionDirective } from "../../../shared/directives/haspermission.directive";

@Component({
    templateUrl: "./shipment-quote-list.component.html",
    styleUrls: ["./shipment-quote-list.component.scss"],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        RouterLink,
        GridColumnDisplayComponent,
        MatPaginatorModule,
        MaterialGridEmptyTableComponent,
        MatButtonModule,
        MatTooltipModule,
        HasPermissionDirective,
    ]
})
export class ShipmentQuoteListComponent extends GridBaseComponent<ShipmentQuoteListDto> {
  protected _matDialog: MatDialog;
  protected _route = inject(ActivatedRoute);
  protected _userResolverService: UserResolverService;
  protected _spinnerService: SpinnerService;
  protected _snackBarService: SnackBarService;
  protected _requestService: RequestService;
  protected _filtersClient: FiltersClient;
  protected _userSettingService: UserSettingService;
  private _cancelShipmentService = inject(CancelShipmentService);
  private _shipmentsClient = inject(ShipmentsClient);
  private _router = inject(Router);

  isASMUser: boolean = false;
  force = new Subject<any>();
  force$ = this.force.asObservable();
  selectedColumnsString: Array<string> = [];

  constructor() {
    const _matDialog = inject(MatDialog);
    const _userResolverService = inject(UserResolverService);
    const _spinnerService = inject(SpinnerService);
    const _snackBarService = inject(SnackBarService);
    const _requestService = inject(RequestService);
    const _filtersClient = inject(FiltersClient);
    const _userSettingService = inject(UserSettingService);

    super(
      _matDialog,
      _userResolverService,
      _spinnerService,
      _snackBarService,
      _filtersClient,
      _userSettingService,
      _requestService
    );
    this._matDialog = _matDialog;
    this._userResolverService = _userResolverService;
    this._spinnerService = _spinnerService;
    this._snackBarService = _snackBarService;
    this._requestService = _requestService;
    this._filtersClient = _filtersClient;
    this._userSettingService = _userSettingService;


    this.listTypes = ListTypeEnum.Quotes;
    _userResolverService.userInfo.subscribe((x) => (this.isASMUser = x.IsASM));
  }

  getFilters(): Observable<FilterDto[]> {
    return of([]);
  }

  getGridColumns() {
    return this._shipmentsClient.shipments_GetShipmentQuotesGridOptions();
  }

  getData() {
    return this._shipmentsClient.shipments_GetShipmentQuotesPagedList(
      this.getPagedRequest(),
      this.searchTerm
    );
  }

  setState(dto: UserSettingDto) {
    // override to always get all columns
    this.savedState = dto?.value
      ? new ApiGridStateModel(JSON.parse(dto.value))
      : new ApiGridStateModel();
    this.savedState.columns = this.allColumns.map((x) => x.columnName ?? "");
    this.saveState();
    this.setColumns();
    this.selectedColumns = [
      GridColumnDto.fromJS({
        columnType: ColumnTypeEnum.Text,
        columnName: "delete",
        label: "",
        displayColumn: true,
        selected: true,
        sortable: false,
      }),
      ...this.selectedColumns,
    ];
    this.selectedColumnsString = this.selectedColumns.map(
      (x) => x.columnName ?? ""
    );
  }

  cancelShipment(id: number) {
    var refreshDataCallback = this.refreshData.bind(this);
    this._cancelShipmentService.cancelShipment(id, null, refreshDataCallback);
  }

  trackShipmentQuote(index: number, item: ShipmentQuoteListDto) {
    return item.id;
  }

  newShipmentQuote() {
    this._router.navigate(["/shipments/new"]);
  }
}
